<template>
  <div class="mb-7">
    <div class="d-flex justify-content-between align-items-center p-4 pb-0">
      <h3>
        {{ fieldDefinition && $t(`document.documentRecording.${fieldDefinition.label.replace(/\s+/g, '')}`) }}
        <el-form-item :prop="fieldKey" :rules="rule" />
      </h3>
      <div class="d-flex">
        <el-dropdown
          trigger="click"
          :placement="$t('direction') === 'rtl' ? 'bottom-start' : 'bottom-end'"
          @command="(method) => _self[method]()"
        >
          <Button type="icon" class="p-0">
            <KebabIcon width="24px" height="24px" />
          </Button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="clearable" command="resetInputData">
              {{ $t('document.documentRecording.reset') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <template v-if="structureParams.references.items">
      <div v-for="(note, index) in value" :key="arrayKeys[index]" class="card mb-4">
        <div class="card-header">
          <el-button size="mini" icon="el-icon-remove" class="float-right" @click="removeItem(index)" />
          <div class="d-flex justify-content-around">
            <autoinput
              :key="`${fieldKey}.${index}.documentNumber`"
              :field-key="`${fieldKey}.${index}.documentNumber`"
            />
            <autoinput :key="`${fieldKey}.${index}.issueDate`" :field-key="`${fieldKey}.${index}.issueDate`">
              <el-form-item
                slot-scope="scope"
                :prop="scope.fieldKey"
                :rules="scope.rule"
                :show-message="false"
                class="mb-0"
              >
                <input-date
                  :value="scope.value"
                  :disabled="!structureParams.references.issueDate"
                  format="dd/MM/yyyy"
                  class="w-100"
                  @input="scope.input"
                />
              </el-form-item>
            </autoinput>
            <autoinput :key="`${fieldKey}.${index}.netAmount`" :field-key="`${fieldKey}.${index}.netAmount`" />
            <autoinput :key="`${fieldKey}.${index}.totalAmount`" :field-key="`${fieldKey}.${index}.totalAmount`" />
          </div>
        </div>
        <autoinput :key="`${fieldKey}.${index}.items`" :field-key="`${fieldKey}.${index}.items`" />
      </div>
      <div class="d-flex justify-content-end mb-4">
        <el-button size="mini" icon="el-icon-circle-plus" @click="addItem()"> Add another note </el-button>
      </div>
    </template>
    <div v-else class="card table-responsive p-2">
      <table class="notes-table">
        <thead>
          <tr>
            <th class="text-center" style="width: 37px"><p class="fw-bold">#</p></th>
            <th v-if="structureParams.references.documentNumber">
              <p class="fw-bold">{{ $t('document.exports.schema.referencesFields.documentNumber') }}</p>
            </th>
            <th v-if="structureParams.references.issueDate">
              <p class="fw-bold">{{ $t('document.exports.schema.referencesFields.issueDate') }}</p>
            </th>
            <th v-if="structureParams.references.paymentDueDate">
              <p class="fw-bold">{{ $t('document.exports.schema.referencesFields.paymentDueDate') }}</p>
            </th>
            <th v-if="structureParams.references.netAmount">
              <p class="fw-bold">{{ $t('document.exports.schema.referencesFields.netAmount') }}</p>
            </th>
            <th v-if="structureParams.references.totalAmount">
              <p class="fw-bold">{{ $t('document.exports.schema.referencesFields.totalAmount') }}</p>
            </th>
            <th v-if="structureParams.references.debitAmount">
              <p class="fw-bold">{{ $t('document.exports.schema.referencesFields.debitAmount') }}</p>
            </th>
            <th v-if="structureParams.references.creditAmount">
              <p class="fw-bold">{{ $t('document.exports.schema.referencesFields.creditAmount') }}</p>
            </th>
            <th v-if="structureParams.references.balance">
              <p class="fw-bold">{{ $t('document.exports.schema.referencesFields.balance') }}</p>
            </th>
            <th style="width: 30px" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(note, index) in value" :key="arrayKeys[index]">
            <td class="text-center align-middle">
              <p>{{ index + 1 }}</p>
            </td>
            <td v-if="structureParams.references.documentNumber">
              <autoinput
                :key="`${fieldKey}.${index}.documentNumber`"
                :field-key="`${fieldKey}.${index}.documentNumber`"
              />
            </td>
            <td v-if="structureParams.references.issueDate">
              <autoinput :key="`${fieldKey}.${index}.issueDate`" :field-key="`${fieldKey}.${index}.issueDate`">
                <el-form-item
                  slot-scope="scope"
                  :prop="scope.fieldKey"
                  :rules="scope.rule"
                  :show-message="false"
                  class="mb-0"
                >
                  <input-date :value="scope.value" format="dd/MM/yyyy" class="w-100" @input="scope.input" />
                </el-form-item>
              </autoinput>
            </td>
            <td v-if="structureParams.references.paymentDueDate">
              <autoinput
                :key="`${fieldKey}.${index}.paymentDueDate`"
                :field-key="`${fieldKey}.${index}.paymentDueDate`"
              >
                <el-form-item
                  slot-scope="scope"
                  :prop="scope.fieldKey"
                  :rules="scope.rule"
                  :show-message="false"
                  class="mb-0"
                >
                  <input-date :value="scope.value" format="dd/MM/yyyy" class="w-100" @input="scope.input" />
                </el-form-item>
              </autoinput>
            </td>
            <td v-if="structureParams.references.netAmount">
              <autoinput :key="`${fieldKey}.${index}.netAmount`" :field-key="`${fieldKey}.${index}.netAmount`" />
            </td>
            <td v-if="structureParams.references.totalAmount">
              <autoinput :key="`${fieldKey}.${index}.totalAmount`" :field-key="`${fieldKey}.${index}.totalAmount`" />
            </td>
            <td v-if="structureParams.references.debitAmount">
              <autoinput :key="`${fieldKey}.${index}.debitAmount`" :field-key="`${fieldKey}.${index}.debitAmount`" />
            </td>
            <td v-if="structureParams.references.creditAmount">
              <autoinput :key="`${fieldKey}.${index}.creditAmount`" :field-key="`${fieldKey}.${index}.creditAmount`" />
            </td>
            <td v-if="structureParams.references.balance">
              <autoinput :key="`${fieldKey}.${index}.balance`" :field-key="`${fieldKey}.${index}.balance`" />
            </td>
            <td class="align-middle text-center">
              <el-dropdown
                trigger="click"
                :placement="$t('direction') === 'rtl' ? 'bottom-start' : 'bottom-end'"
                @command="(method) => _self[method](index)"
              >
                <Button type="icon" class="p-0">
                  <KebabIcon width="24px" height="24px" />
                </Button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="addItemRowAbove">
                    {{ $t('document.documentRecording.addRowAbove') }}
                  </el-dropdown-item>
                  <el-dropdown-item command="removeItem">
                    {{ $t('document.documentRecording.removeRow') }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </td>
          </tr>

          <tr :key="nextRandomKey">
            <td class="text-center align-middle">
              <i class="el-icon-circle-plus" @click="convertItem" />
            </td>
            <td v-if="structureParams.references.documentNumber">
              <el-form-item class="mb-0">
                <el-input class="w-100" @focus="convertItem" />
              </el-form-item>
            </td>
            <td v-if="structureParams.references.issueDate">
              <input-date class="w-100" @focus="convertItem" />
            </td>
            <td v-if="structureParams.references.paymentDueDate">
              <input-date class="w-100" @focus="convertItem" />
            </td>
            <td v-if="structureParams.references.netAmount">
              <el-form-item class="mb-0">
                <input-money class="w-100" @focus="convertItem" />
              </el-form-item>
            </td>
            <td v-if="structureParams.references.totalAmount">
              <el-form-item class="mb-0">
                <input-money class="w-100" @focus="convertItem" />
              </el-form-item>
            </td>
            <td v-if="structureParams.references.debitAmount">
              <el-form-item class="mb-0">
                <input-money class="w-100" @focus="convertItem" />
              </el-form-item>
            </td>
            <td v-if="structureParams.references.creditAmount">
              <el-form-item class="mb-0">
                <input-money class="w-100" @focus="convertItem" />
              </el-form-item>
            </td>
            <td v-if="structureParams.references.balance">
              <el-form-item class="mb-0">
                <input-money class="w-100" @focus="convertItem" />
              </el-form-item>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script type="text/javascript">
import Autoinput from '@/modules/templates/components/autoform/autoinput';
import AutoformArray from '@/modules/templates/components/autoform/autoform_array';
import InputDate from '@/modules/core/components/forms/InputDate';
import { KebabIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import InputMoney from '../InputMoney';

export default {
  components: { Autoinput, InputDate, KebabIcon, Button, InputMoney },
  extends: AutoformArray,
  props: {
    structureParams: { type: Object, required: true },
    clearable: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return { nextRandomKey: Math.random() };
  },
  methods: {
    async convertItem() {
      const focusedInput = this.$el.querySelector('input:focus');
      const allInputs = Array.from(this.$el.querySelectorAll('input'));
      const focusIndex = allInputs.indexOf(focusedInput);

      this.addItem(this.nextRandomKey);
      this.nextRandomKey = Math.random();

      await this.$nextTick();
      const newInput = Array.from(this.$el.querySelectorAll('input'))[focusIndex];
      newInput.focus();
    },
    resetInputData() {
      this.$emit('reset-references');
    },
  },
};
</script>
<style lang="scss" scoped>
.notes-table {
  ::v-deep td,
  th {
    padding: 5px;
  }
}
</style>
